<template>
  <div :class="'ol-product-content__center-list carousel-products-container '+carouselKey">
    <div v-for="(product,i) in products" :key="'item-'+i+'-'+product[0].r_uni_products_main.id+'-'+product[0].r_uni_products_main.slug">
      <router-link :to="product[0].r_uni_products_main.bundling == 1 ? '/bundle/'+product[0].r_uni_products_main.slug : '/product/'+product[0].r_uni_products_main.slug" :title="product[0].r_uni_products_main.name">
        
        <div class="ol-home-product__content-item-list--item" style="width:100%">
          <div class="more-product-item">
            <div class="card-product-carausel">
              
                <div 
                    :style="{
                    backgroundImage: product[0].r_uni_products_main.r_uni_product_suasanas && product[0].r_uni_products_main.r_uni_product_suasanas.length > 0 ? 
                    'url(' + product[0].r_uni_products_main.r_uni_product_suasanas[0].image_url + ')' : 
                    'url(' + (product[0].r_uni_products_main.r_uni_product_images && product[0].r_uni_products_main.r_uni_product_images.length > 0 ? product[0].r_uni_products_main.r_uni_product_images[0].image_url : '') + ')',
                    display: 'block',
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }">
                    <div v-if="product[0].r_uni_products_main.stock == 0" >
                      <div v-if="product[0].r_uni_products_main.stock == 0" class="label-stock-empty" >Stok Habis</div>
                      <div v-if="product[0].r_uni_products_main.stock == 0" class="image-stock-empty" ></div>
                    </div>
                    <div v-if="product[0].r_uni_products_main.seo_4 == 'GRATIS'" class="promotion-1">
                        <span class="one-lines"><b>{{ product[0].r_uni_products_main.seo_4 }}</b>&nbsp;{{ product[0].r_uni_products_main.seo_5 }}</span>
                    </div>
                </div>
            </div>
            <div class="content-card-product">
                <h3 class="ol-home-product__content-item-list--item-title">
                    {{product[0].r_uni_products_main.name}}
                </h3>
                <div class="flex-card-product">
                    <div class="price-discount-card-product">{{product[0].r_uni_products_main.price_normal_min}}</div>
                    <div class="box-discount-card-product">
                        <div class="discount-card-product">{{product[0].r_uni_products_main.discount_max}}</div>
                    </div>
                    <div v-if="product[0].r_uni_products_main.status_voucher != 'Rp0'" class="voucher-discount-card-product">
                        <img  :src="require('@/assets/images/benefit/voucher.png')"  height="100%">
                    </div>
                </div>
                <div class="price-card-product">
                    {{product[0].r_uni_products_main.price_discount_min}}
                </div>
                <div class="review-card-product review-catalog-product" v-if="product[0].r_uni_products_main.total_sales != 0">
                    <span ><i class="fas fa-star" style="color:#FFCC00;"></i></span>
                    <span> {{product[0].r_uni_products_main.rating}}</span>
                    <span>|</span>
                    <span>{{product[0].r_uni_products_main.total_sales}}</span>
                    <span>Terjual</span>
                </div>
                <div class="review-card-product" v-else>
                    &nbsp;
                </div>
                <img  :src="require('@/assets/images/info-gratis-jawa-bali.png')" class="free-card-product">
            </div>
          </div>


        </div>
      </router-link>
      
    </div>
  </div>
</template>

<script>
export default {
  name: "CarouselProductFree",
  props: ['products', 'carouselKey'],
  mounted() {
    this.initSlick(this.carouselKey);
  },
  methods: {
    initSlick(carouselKey) {
      $(".carousel-products-container."+carouselKey).slick({
        slidesToScroll: 1,
        slidesToShow: 5,
        dots: !0,
        autoplay: !0,
        autoplaySpeed: 5e3,
        arrows: !0,
        pauseOnFocus: !1,
        infinite: !0,
        speed: 500,
        fade: !1,
        responsive: [{
          breakpoint: 550,
          settings: {
            slidesToShow: 2,
          },
        }],
      });
    }
  }
};
</script>

<style lang="css" scoped>
@import "../../assets/css/_endorsement.css";
@import "../../assets/js/slick/slick.css";
@import '../../assets/css/bootstrap.css';

.label-free-product {
  /* height: 35px; */
  border-radius: 0px 0px 8px 8px;
  padding: 8px;
  background: #C13B37;
  margin: -8px 2px 4px 2px;
  color: white;
  font-size: 10px;
  text-align: left;
}

.label-free {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-height: 24px;
  line-height: 12px;
}

</style>
