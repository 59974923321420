var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:'ol-product-content__center-list carousel-products-container '+_vm.carouselKey},_vm._l((_vm.products),function(product,i){return _c('div',{key:'item-'+i+'-'+product.id+'-'+product.slug},[_c('router-link',{attrs:{"to":product.bundling == 1 ? '/bundle/'+product.slug : '/product/'+product.slug,"title":product.name}},[_c('div',{staticClass:"ol-home-product__content-item-list--item",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"more-product-item"},[_c('div',{staticClass:"card-product-carausel"},[_c('div',{style:({
                  backgroundImage: product.r_uni_product_suasanas && product.r_uni_product_suasanas.length > 0 ? 
                  'url(' + product.r_uni_product_suasanas[0].image_url + ')' : 
                  'url(' + (product.r_uni_product_images && product.r_uni_product_images.length > 0 ? product.r_uni_product_images[0].image_url : '') + ')',
                  display: 'block',
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover'
              })},[(product.stock == 0)?_c('div',[(product.stock == 0)?_c('div',{staticClass:"label-stock-empty"},[_vm._v("Stok Habis")]):_vm._e(),(product.stock == 0)?_c('div',{staticClass:"image-stock-empty"}):_vm._e()]):_vm._e(),(product.seo_4 == 'KOMBO HEMAT')?_c('div',{staticClass:"promotion-1"},[_c('span',{staticClass:"one-lines"},[_c('b',[_vm._v(_vm._s(product.seo_4))]),_vm._v(" "+_vm._s(product.seo_5))])]):_vm._e()])]),_c('div',{staticClass:"content-card-product"},[_c('h3',{staticClass:"ol-home-product__content-item-list--item-title"},[_vm._v(" "+_vm._s(product.name)+" ")]),_c('div',{staticClass:"flex-card-product"},[_c('div',{staticClass:"price-discount-card-product"},[_vm._v(_vm._s(product.price_normal_min))]),_c('div',{staticClass:"box-discount-card-product"},[_c('div',{staticClass:"discount-card-product"},[_vm._v(_vm._s(product.discount_max))])]),(product.status_voucher != 'Rp0')?_c('div',{staticClass:"voucher-discount-card-product"},[_c('img',{attrs:{"src":require('@/assets/images/benefit/voucher.png'),"height":"100%"}})]):_vm._e()]),_c('div',{staticClass:"price-card-product"},[_vm._v(" "+_vm._s(product.price_discount_min)+" ")]),(product.total_sales != 0)?_c('div',{staticClass:"review-card-product review-catalog-product"},[_c('span',[_c('i',{staticClass:"fas fa-star",staticStyle:{"color":"#FFCC00"}})]),_c('span',[_vm._v(" "+_vm._s(product.rating))]),_c('span',[_vm._v("|")]),_c('span',[_vm._v(_vm._s(product.total_sales))]),_c('span',[_vm._v("Terjual")])]):_c('div',{staticClass:"review-card-product"},[_vm._v("   ")]),_c('img',{staticClass:"free-card-product",attrs:{"src":require('@/assets/images/info-gratis-jawa-bali.png')}})])])])])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }